const data = [
  {
    id: '1',
    title: 'Neural Network Security Auditor',
    description:
      'A full stack open-source aggregator model focusing on three areas of Deep Neural Network(DNN) security: 1) Privacy, 2) Evasion attacks, and 3) Adversarial Examples.',
    image: {
      src: 'https://www.wgu.edu/content/dam/web-sites/blog-newsroom/blog/images/national/2020/march/neural-network.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://rsa02.netlify.app/',
      repo: 'https://github.com/MohamedA2000/DeepLearningSecurityToolsRepository',
    },
    technologies: [
      '#typescript',
      '#react',
      '#python',
      '#tensorflow',
      '#google cloud platform',
      '#ml-security',
      '#keras',
    ],
  },
  {
    id: '2',
    title: 'Real Time Stock Market Data Pipeline',
    description:
      'This repository contains the code and configuration files for an end-to-end data pipeline built using Apache Kafka, ZooKeeper, and a suite of AWS services. The pipeline is designed to ingest, process, store, and analyze streaming data in real-time, leveraging Amazon S3 for scalable and durable storage of raw data. The entire system is hosted on an AWS EC2 instance, providing the computational infrastructure for data processing.',
    image: {
      src: 'https://media.licdn.com/dms/image/C4D12AQEguYlcf8g9rw/article-cover_image-shrink_720_1280/0/1520126237937?e=2147483647&v=beta&t=h1L2Pmwe78jl57qJbHvx34FF5Exw3po457ZQSHGif5Y',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/MohamedA2000/Stock-Market-Data-Pipeline',
    },
    technologies: [
      '#python',
      '#jupyter-notebook',
      '#apache-kafka',
      '#SQL',
      '#aws-ec2',
      '#aws-s3',
    ],
  },
  {
    id: '3',
    title: 'NBA Machine Learning Game Predictor',
    description:
      ' This project involves scraping NBA game data from the past five years and building a Ridge regression model to predict the accuracy of final scores. The model has achieved an accuracy rate of over 63%, making it a valuable tool for predicting NBA game outcomes.',
    image: {
      src: 'https://www.insidescience.org/sites/default/files/sites/all/default/files/SecondSpectrumTracking.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/MohamedA2000/NBA-ML-Predictor',
    },
    technologies: [
      '#python',
      '#playwright',
      '#beautiful-soup',
      '#scikit-learn',
      '#pandas',
    ],
  },
  {
    id: '4',
    title: 'Library Management System',
    description:
      'A library management system that allows users to search for books by title, author, publication date, genre, and availability. It also displays fines associated with customer accounts, customer information, and book locations in the library. The application is built using Java and utilizes SQL queries to interact with a database.',
    image: {
      src: 'https://prod-discovery.edx-cdn.org/media/course/image/c332bd5d-2ff1-4f11-868a-6644076c904e-a44b6765f142.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/MohamedA2000/Library-Management-Database',
    },
    technologies: ['#SQL', '#java'],
  },
  {
    id: '5',
    title: 'Automated Mobile Data Scraper',
    description:
      "A script that will automatically log in to Koodo Mobile's website, retrieve data usage information, and send it as a text message to your mobile device via Twilio. Cron job set up to send regular updates",
    image: {
      src: 'https://images.prismic.io/oxylabs-sm/eba26704-d9e7-4562-aaf4-0375a6bb9693_Python+Web+Scraping+1200x600.png?auto=compress,format&rect=0,0,1201,601&w=1200&h=600&fm=webp&q=75',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/MohamedA2000/Automated-Mobile-Data-Scraper',
    },
    technologies: [
      '#python',
      '#selenium',
      '#twilio',
      '#api',
      '#cron',
    ],
  },
  {
    id: '6',
    title: 'Diverse Machine Learning Projects Showcase',
    description:
      'A collection of machine learning and intelligent systems projects, including linear regression, logistic regression, clustering (K-Means, DBSCAN, Nearest-Neighbor), and neural networks, analyzing the expected fatality and life expctancy of heart disease patients based on external factors.',
    image: {
      src: 'https://www.einfochips.com/blog/wp-content/uploads/2018/11/how-to-develop-machine-learning-applications-for-business-featured.jpg',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/MohamedA2000/Machine-Learning-Projects',
    },
    technologies: [
      '#python',
      '#jupyter-notebook',
      '#pandas',
      '#machine-learning',
      '#neural-networks',
      '#regression',
      '#scikit-learn',
      '#clustering',
    ],
  },
  // {
  //   id: '7',
  //   title: 'Places Mobile App',
  //   description:
  //     'A Mobile application for sharing places that people visit from all over the world. Uses React Native, TypeScript, MobX and Native Device Features',
  //   image: {
  //     src: 'https://res.cloudinary.com/dx6tl6aa2/image/upload/v1628348073/portfolio/projects/rn-places/rn-places-lg_ljyikm.jpg',
  //     placeholderSrc:
  //       'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
  //   },
  //   links: {
  //     site: 'https://expo.dev/@v_snaichuk/rn-places',
  //     repo: 'https://github.com/vsnaichuk/RN-places',
  //   },
  //   technologies: [
  //     '#react-native',
  //     '#typescript',
  //     '#expo',
  //     '#mobx',
  //     '#sqlite',
  //     '#react-native-maps',
  //     '#expo-location',
  //   ],
  // },
  // {
  //   id: '8',
  //   title: 'Shopping Cart',
  //   description:
  //     'Simple eCommerce cart application built with React and TypeScript. Uses SWR hook to fetch the data from the API and Styled Components',
  //   image: {
  //     src: 'https://res.cloudinary.com/dx6tl6aa2/image/upload/v1628348073/portfolio/projects/react-shop-cart/rn-shop-cart-lg_w3qxn6.jpg',
  //     placeholderSrc:
  //       'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
  //   },
  //   links: {
  //     site: 'https://shopping-cart-sv.netlify.app/',
  //     repo: 'https://github.com/vsnaichuk/React-ShoppingCart',
  //   },
  //   technologies: [
  //     '#react',
  //     '#typescript',
  //     '#material-ui',
  //     '#styled-components',
  //     '#swr',
  //     '#fakestoreapi',
  //   ],
  // },
];

export default data;
