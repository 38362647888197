// PDFViewer.js
import React from 'react';

const PDFViewer = () => {
  return (
    <div>
      <iframe
        src="src/assets/Mohamed_Abuomar_Resume_M.pdf"
        width="100%"
        height="500px"
        title="PDF Viewer"
      ></iframe>
    </div>
  );
};

export default PDFViewer;
